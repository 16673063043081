.burst {
    font-family: "Roboto";
    font-size: 13px;
    line-height: 1.5em;
    margin: 5px 0 15px;
  }
  .burst .freeli {
    list-style: none;
    position: relative;
    padding: 0 0 0 20px;
  }
  
  .burst.burst .freeli::before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    background: #ff6600;
    width: 10px;
    height: 10px;
    text-align: center;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  .burst.burst .freeli::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    height: 10px;
    width: 10px;
    background: #ff6600;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }