
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&family=Poppins:wght@100;200;300;400;500;600;700&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  /* fonts */
  font-family: 'Poppins', sans-serif;
  /* fonts */
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  overflow-x: hidden;
  color: #3c4043;
  background: rgb(255,239,223);
  background: linear-gradient(90deg, rgba(255,239,223,1) 0%, rgba(255,246,203,1) 100%, rgba(255,240,210,1) 100%);
}

.heading{
  @apply text-2xl md:text-5xl font-medium;
}
.sub_heading{
  @apply md:text-2xl font-semibold;
}
.caption{
  @apply text-[#5f6368] text-lg;
}

.text_accent{
  @apply text-orange-500
}
.bg_accent{
  background-image: linear-gradient(to right, rgb(251, 146, 60), rgb(251, 113, 133))
}