/* @import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css"; */
.slick-prev:before, 
.slick-next:before {
     color: orangered;
     font-size: 40px;
     display: none;
}

.slick-next:before{
  position: absolute;
  right:0;
   margin-top: -10px;
   margin-right: 5px;
}

.slick-next,
.slick-prev
{
  position: absolute;
  right:0;
 }

.slick-prev{
  z-index: 99999;
  margin-left: 25px;
}

.category-card:hover .arrow-icon{
  fill: white;
}

.slick-arrow{
  position: absolute;
  right:0;
 }

.slick-dots {
  @apply hidden
 }

