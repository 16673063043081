.wave {
    background-image: linear-gradient(to right, rgb(255, 150, 63),white);
    padding: 50px;
    position: relative;
  }
  .wave.wave-bottom:after, .wave.wave-top:after {
    content: "";
    display: block;
    margin: 0;
    padding: 0;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 100% 64px;
    position: absolute;
    left: 0;
    width: 100%;
    height: 64px;
  }
  @media (max-width: 768px) {
    .wave.wave-bottom:after, .wave.wave-top:after {
      height: 32px;
      background-size: 100% 32px;
    }
  }
  @media (max-width: 349px) {
    .wave.wave-bottom:after, .wave.wave-top:after {
      height: 16px;
      background-size: 100% 16px;
    }
  }
  .wave.wave-bottom:after {
    bottom: 0;
    background-image: url("https://triss.dev/examples/wave.php?color=white&orientation=0deg");
  }
  .wave.wave-top:after {
    top: 0;
    background-image: url("https://triss.dev/examples/wave.php?color=white&orientation=180deg");
  }