
#chk{
	display: none;
}
.signup{
	position: relative;
	width:100%;
	height: 100%;
}
.label{
	font-size: 2.3em;
	justify-content: center;
	display: flex;
	margin: 50px 0;
	font-weight: bold;
	cursor: pointer;
	transition: .5s ease-in-out;
}
.input{
	width: 80%;
	justify-content: center;
	display: flex;
	margin: 12px auto;
	outline: none;
	border-radius: 5px;
}
.button{
	width: 60%;
	height: 40px;
	margin: 10px auto;
	justify-content: center;
	display: block;
	color: #fff;
	font-size: 1em;
	font-weight: bold;
	margin-top: 20px;
	outline: none;
	border: none;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}

.login{
	height: 460px;
	background: rgb(247, 247, 247);
	border-radius: 60% / 10%;
	transform: translateY(-160px);
	transition: .8s ease-in-out;
}
.login .label{
	transform: scale(.6);
}

#chk:checked ~ .login{
	transform: translateY(-450px);
}
#chk:checked ~ .login .label{
	transform: scale(1);	
}
#chk:checked ~ .signup .label{
	transform: scale(.6);
}

.waveContainer{
	height: 100vh;
	overflow: hidden;
  }
  .viewBox{
	height: 100%;
	width: 100%;
  }
  .path{
	stroke: none;
	fill: #ffecca
	
  }